import OnSiteInterpretation from "./OnSiteInterpretation"

function Interpreting() {
  return (
    <>
    
    <OnSiteInterpretation/>
    </>
  )
}

export default Interpreting