import ContactUsTranslationForm from "../Forms/ContactUsTranslationForm"

export default function Translation() {
  return (
    <>
    <div  className="hero">
    <div  className="container">
        <div  className="row justify-content-between">
            <div  className="col-lg-6">
                <div  className="intro-excerpt">
                    <h1 style={{fontSize:'44px'}}>Translation Service Languages</h1>
                    <p  className="mb-4" style={{fontSize:'20px'}}>
                    With our extensive range of translation services, we are proud to offer support in numerous languages, enabling us to bridge communication gaps across the globe. Our talented team of translators can assist you with the following languages.
                       </p>
                    
                </div>
            </div>
            <div  className="col-lg-6">
                <div  className="hero-img-wrap">
                    <img src="https://th.bing.com/th/id/OIP.6vL3KIGlRYq2IqccGZzwJQHaE8?rs=1&pid=ImgDetMain" alt="feautred_images_front" style={{borderRadius:'30px',width:'700px'}}  className="img-fluid " />
                </div>
            </div>
        </div>
    </div>
</div>

<div className="we-help-section" style={{marginTop:'50px',marginBottom:'10px'}}>
      <div className="container" style={{paddingLeft:'150px',paddingRight:'150px'}}>
        <div className="row justify-content-between">
          
          <div className="col-lg-12" style={{alignContent:'center',textAlign:'center'}} >
            <h1 className="section-title mb-4">Translation Service Languages Offered By Zeeba H Solutions</h1>
            {/* <h4  style={{fontWeight:'600',fontSize:'18px',lineHeight:'30px'}}>
              
            </h4> */}
          
            <div className="mt-5" style={{padding:'30px',paddingLeft:'60px'}}>
            <ul className='row languages_list' style={{textAlign:'left'}}>
<li className='col-lg-4 col-md-6 col-sm-12'>Afrikaans</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Albanian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Amharic*</li>
<li className='col-lg-12 col-md-12 col-sm-12'>Arabic (MSA*, Iraqi*, Lebanese, Yemeni*, Moroccan, Egyptian, Saudi Arabian, Jordanian, Sudanese, Syrian)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Arabic Levantine* (IVR only)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Armenian (Eastern)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Azerbaijani</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Bambara</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Baluchi*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Bangla (Bangla)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Bosnian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Bulgarian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Burmese*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Cambodian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Cape Verdean (Balavento)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Cape Verdean (Sotavento)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Chinese Mandarin*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Chinese Cantonese*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Chuukese*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Croatian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Czech*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Danish*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Dari*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Dutch*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>English*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Fante (Akan)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Farsi*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Finnish*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>French*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>French Canadian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Fulani* (Puula language from Senegal)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Ga</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Georgian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>German*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Greek*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Gujarati*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Haitian Creole*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Hausa*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Hebrew*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Hindi*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Hmong*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Hungarian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Igbo</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Ilocano</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Indonesian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Italian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Jamaican creole English (Patois)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Japanese*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Kannada</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Karenni</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Kazakh</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Kinyarwanda</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Korean*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Kurmanji*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Lao</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Latvian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Lithuanian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Macedonian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Malay*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Malayalam*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Marathi</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Mongolian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Navajo</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Nepali*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Norwegian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Oromo*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Pashto*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Pashto (Pakistan)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Polish*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Portuguese (Brazil)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Portuguese (Portugal)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Punjabi (Eastern)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Punjabi (Western)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Romanian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Russian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Samoan*(IVR only)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Serbian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Serbo-Croatian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Sinhala*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Slovak*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Slovenian</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Somali*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Sorani*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Spanish Latin America*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Spanish Puerto Rico</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Spanish Spain</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Swahili</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Swedish*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Tagalog*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Taiwanese</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Tajik</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Tamil (India)*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Telugu*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Thai*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Tibetan</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Tigrinya*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Turkish*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Turkmen</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Turkmen (Iraqi)</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Twi</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Ukrainian*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Urdu*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Uzbek</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Vietnamese*</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Wolof</li>
<li className='col-lg-4 col-md-6 col-sm-12'>Yoruba*</li>
</ul>
            </div>
          </div>
        </div>
      </div>
        </div>

        <ContactUsTranslationForm />
</>
  )
}
