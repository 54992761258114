import './FieldsFeatured.css';

function FieldsFeatured(){
        return(
            <section  className="interpretation-services section-padding-160">
    <div  className="container">
        <div  className="row">
            <div  className="col-md-6 right">
                <ul  className="int-services">
                    <li>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M50 25C50 25.2789 49.9954 25.5568 49.9863 25.8336C49.9826 25.9465 49.5779 25.7542 49.5728 25.8667C49.5322 26.744 49.4206 27.4566 49.292 28.3081C49.2634 28.4978 49.6587 29.1448 49.6259 29.3331C47.574 41.0749 37.3293 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z" fill="#0F358F"></path>
                            <path d="M44.6895 21.8438C44.6895 31.3361 36.9943 39.0312 27.502 39.0312C18.0096 39.0312 10.3145 31.3361 10.3145 21.8438C10.3145 12.3514 18.0096 4.65625 27.502 4.65625C36.9943 4.65625 44.6895 12.3514 44.6895 21.8438Z" fill="#4F73F9"></path>
                            <path d="M39.919 29.2084C37.9374 33.9916 29.0792 41.1401 23.9474 44.3792C22.8005 45.1031 21.3394 45.1031 20.1925 44.3792C15.0607 41.1401 6.20247 33.9916 4.22092 29.2084C-2.2904 13.4909 14.1693 8.09071 22.0699 15.616C29.9706 8.09071 46.4303 13.4909 39.919 29.2084Z" fill="#F7B422"></path>
                            <path d="M29.7653 40.1992C27.675 41.8615 25.6146 43.3265 23.9474 44.3787C22.8004 45.1026 21.3394 45.1026 20.1925 44.3787C15.0607 41.1397 6.20247 33.9911 4.22092 29.2079C1.16868 21.8403 3.16399 16.7396 6.95901 14.1748C6.80462 20.1917 9.57991 26.986 14.9849 32.391C19.3812 36.7874 24.6966 39.4438 29.7653 40.1992Z" fill="#FFCF67"></path>
                            <path d="M36.9499 23.928C35.0604 25.1314 27.9945 19.2921 28.8301 17.1534C29.3321 15.8683 32.9509 15.2446 35.4124 17.1054C38.1026 19.1389 38.1332 23.1743 36.9499 23.928Z" fill="#FFCF67"></path>
                            <path d="M18.5547 25.8789C18.5547 27.2272 17.4616 28.3203 16.1133 28.3203C14.7649 28.3203 13.6719 27.2272 13.6719 25.8789C13.6719 24.5306 14.7649 23.4375 16.1133 23.4375C17.4616 23.4375 18.5547 24.5306 18.5547 25.8789Z" fill="#FFCF67"></path>
                            <path d="M39.6926 34.2917V38.4078C39.6926 38.9471 39.2554 39.3844 38.716 39.3844H34.3819V43.7184C34.3819 44.2578 33.9447 44.695 33.4054 44.695H29.2892C28.7498 44.695 28.3126 44.2578 28.3126 43.7184V39.3844H23.9785C23.4392 39.3844 23.002 38.9471 23.002 38.4078V34.2917C23.002 33.7523 23.4392 33.3151 23.9785 33.3151H28.3126V28.981C28.3126 28.4417 28.7498 28.0045 29.2892 28.0045H33.4054C33.9447 28.0045 34.3819 28.4417 34.3819 28.981V33.3151H38.716C39.2553 33.3151 39.6926 33.7523 39.6926 34.2917ZM27.2209 4.39783L26.4602 3.00691C26.3981 2.89353 26.2353 2.89353 26.1731 3.00691L25.4124 4.39783C25.3974 4.42527 25.3748 4.44783 25.3474 4.46287L23.9564 5.22361C23.8431 5.28562 23.8431 5.44851 23.9564 5.51062L25.3474 6.27136C25.3748 6.2864 25.3974 6.30896 25.4124 6.3364L26.1731 7.72732C26.2352 7.8407 26.398 7.8407 26.4602 7.72732L27.2209 6.3364C27.2359 6.30896 27.2585 6.2864 27.2859 6.27136L28.6769 5.51062C28.7902 5.44861 28.7902 5.28572 28.6769 5.22361L27.2859 4.46287C27.2584 4.44783 27.2358 4.42527 27.2209 4.39783Z" fill="#5A7BF3"></path>
                            <path d="M30.2126 33.3155H28.3126V28.9814C28.3126 28.4421 28.7498 28.0049 29.2892 28.0049H32.1657C31.6264 28.0049 31.1892 28.4421 31.1892 28.9814V32.339C31.1892 32.8783 30.752 33.3155 30.2126 33.3155ZM26.8551 33.3155H23.9785C23.4392 33.3155 23.002 33.7527 23.002 34.2921V38.4082C23.002 38.9476 23.4392 39.3848 23.9785 39.3848H26.8551C26.3157 39.3848 25.8785 38.9476 25.8785 38.4082V34.2921C25.8785 33.7527 26.3157 33.3155 26.8551 33.3155ZM31.1892 43.7188V40.3613C31.1892 39.822 30.752 39.3848 30.2126 39.3848H28.3126V43.7188C28.3126 44.2582 28.7498 44.6954 29.2892 44.6954H32.1657C31.6264 44.6954 31.1892 44.2582 31.1892 43.7188Z" fill="#5C7DCA"></path>
                            <path d="M19.5876 8.74099L18.1967 9.50173C18.1692 9.51677 18.1467 9.53933 18.1316 9.56677L17.3709 10.9577C17.3089 11.0712 17.146 11.0712 17.084 10.9577L16.3232 9.56677C16.3082 9.53933 16.2856 9.51677 16.2582 9.50173L14.8673 8.74099C14.7539 8.67898 14.7539 8.51609 14.8673 8.45408L16.2582 7.69334C16.2856 7.6783 16.3082 7.65574 16.3232 7.6283L17.084 6.23738C17.146 6.124 17.3089 6.124 17.3709 6.23738L18.1316 7.6283C18.1467 7.65574 18.1692 7.6783 18.1967 7.69334L19.5876 8.45408C19.701 8.51599 19.701 8.67898 19.5876 8.74099Z" fill="#F7B422"></path>
                        </svg>
                        <p>Healthcare</p>
                    </li>
                    <li>
                        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.3167 44.3909C44.5348 40.0019 47.852 33.4256 47.852 26.0734C47.852 12.8595 37.14 2.14746 23.9261 2.14746C10.7121 2.14746 0 12.8596 0 26.0735C0 30.4003 1.14873 34.4587 3.15752 37.9607L39.3167 44.3909Z" fill="#0F358F"></path>
                            <path d="M10.166 45.648C14.0582 48.3891 18.8039 49.9996 23.9262 49.9996C29.0484 49.9996 33.7941 48.3891 37.6863 45.6479H10.166V45.648Z" fill="#2A428C"></path>
                            <path d="M21.7955 45.6479H10.166C13.4307 47.9472 17.2961 49.4503 21.4803 49.8754C19.5694 49.1503 19.7247 46.1174 21.7955 45.6479Z" fill="#1C2E7A"></path>
                            <path d="M32.5868 30.1287H8.7708C7.9623 30.1287 7.30078 30.7902 7.30078 31.5987V32.659H34.0567V31.5987C34.0568 30.7902 33.3953 30.1287 32.5868 30.1287Z" fill="#022068"></path>
                            <path d="M14.5996 15.2798L22.7263 5.58346L33.9029 14.9508L25.7762 24.6471L14.5996 15.2798Z" fill="#F7B422"></path>
                            <path d="M27.2336 9.59487C27.3312 9.62924 27.4305 9.61684 27.5135 9.57446L22.7422 5.57495L14.6104 15.2759L19.2429 19.1591C19.3088 19.0596 19.3252 18.9282 19.2678 18.808C18.6655 17.5478 19.4512 16.7292 19.4512 16.7292C19.4512 16.7292 23.5893 12.0092 24.8263 10.3491C25.6312 9.26889 26.6324 9.38325 27.2336 9.59487Z" fill="#FFCF67"></path>
                            <path d="M24.2578 3.74209C25.4855 4.77119 34.2289 12.1004 35.4647 13.1362C35.4647 13.1362 34.7315 11.6856 35.8017 10.409C33.6716 8.62353 28.1396 3.98642 26.8843 2.93408C25.8142 4.21074 24.2578 3.74209 24.2578 3.74209Z" fill="#F7B422"></path>
                            <path d="M29.3396 7.69355C29.2975 6.70361 29.9818 6.40029 30.4005 6.30732C30.5182 6.28115 30.6071 6.19609 30.6481 6.08916C28.9829 4.69326 27.4692 3.42451 26.8843 2.93408C25.8142 4.21064 24.2578 3.74209 24.2578 3.74209C24.8224 4.21533 26.9764 6.0209 29.2587 7.93408C29.3127 7.86865 29.3436 7.78418 29.3396 7.69355Z" fill="#FFCF67"></path>
                            <path d="M13.0977 17.0552C14.3254 18.0843 23.0687 25.4135 24.3046 26.4494C24.3046 26.4494 22.7482 25.9808 21.6781 27.2574C19.548 25.4719 14.0161 20.8348 12.7607 19.7825C13.8309 18.5059 13.0977 17.0552 13.0977 17.0552Z" fill="#F7B422"></path>
                            <path d="M13.0977 17.0552C13.0977 17.0552 13.8309 18.5058 12.7607 19.7824C13.3255 20.2559 14.7559 21.4548 16.3525 22.7933C16.4412 22.7186 16.4921 22.6032 16.4762 22.4804C16.3533 21.5331 17.071 21.28 17.5287 21.2142C17.6557 21.1959 17.7561 21.1103 17.8023 20.9989C15.6323 19.1799 13.6377 17.5079 13.0977 17.0552Z" fill="#FFCF67"></path>
                            <path d="M34.1391 19.9583L32.8837 19.8772C32.5309 20.2981 31.0624 22.0499 30.4727 22.7535L30.7718 23.9753C31.1981 25.7171 32.4923 27.1112 34.1929 27.68C35.4334 28.095 36.8903 28.9004 38.4909 30.0512C38.547 29.88 38.6349 29.7163 38.7576 29.5698L41.0422 26.8443C41.1649 26.6978 41.3108 26.5827 41.4696 26.4976C40.0569 25.1225 39.0095 23.8287 38.3843 22.6797C37.5271 21.1046 35.9285 20.0739 34.1391 19.9583Z" fill="#FFCF67"></path>
                            <path d="M35.9711 25.2256C35.5366 25.0669 35.1998 24.8391 34.939 24.5866C34.208 23.879 34.1562 22.7211 34.7775 21.9157L35.9393 20.4094C35.3789 20.1564 34.7714 19.9992 34.1391 19.9583L32.8837 19.8772C32.5309 20.2981 31.0624 22.0499 30.4727 22.7535L30.7718 23.9753C31.1981 25.7171 32.4923 27.1112 34.1929 27.68C35.4334 28.095 36.8903 28.9004 38.4909 30.0512C38.547 29.8801 38.6349 29.7163 38.7576 29.5698L40.0178 28.0665C39.1326 27.278 37.2587 25.6959 35.9711 25.2256Z" fill="#F7B422"></path>
                            <path d="M47.2699 31.338L43.3286 28.0342C43.2729 28.2113 43.1827 28.3807 43.0561 28.5318L40.7715 31.2573C40.6448 31.4085 40.4937 31.5269 40.3291 31.6126L45.6625 36.0834C46.3563 34.5792 46.8987 32.9911 47.2699 31.338Z" fill="#FFCF67"></path>
                            <path d="M44.1827 31.8747C43.5818 31.371 43.4885 30.4772 43.9805 29.8668C43.9862 29.8597 43.9914 29.8533 43.9959 29.8479L44.6444 29.1372L43.3286 28.0342C43.2729 28.2113 43.1827 28.3807 43.0561 28.5318L40.7715 31.2573C40.6448 31.4085 40.4937 31.5269 40.3291 31.6126L45.6625 36.0834C45.9954 35.3617 46.2924 34.6202 46.5534 33.862L44.1827 31.8747Z" fill="#F7B422"></path>
                            <path d="M35.6805 14.8413C35.233 15.3751 34.4377 15.445 33.904 14.9977L22.6971 5.60343C22.1633 5.15597 22.0934 4.36065 22.5407 3.82696C22.9882 3.29318 23.7835 3.22325 24.3172 3.67062L35.5241 13.0648C36.0579 13.5122 36.1279 14.3075 35.6805 14.8413Z" fill="#FFDD40"></path>
                            <path d="M24.3172 3.67062C23.7835 3.22325 22.9881 3.29318 22.5407 3.82696C22.0934 4.36065 22.1633 5.15606 22.6971 5.60343L27.4611 9.59688C27.5947 9.55362 27.6994 9.43448 27.708 9.2796C27.7584 8.37452 28.5595 8.12686 29.0338 8.05977C29.1581 8.04219 29.2583 7.96104 29.3069 7.85323L24.3172 3.67062Z" fill="#FFB332"></path>
                            <path d="M38.1384 10.2697C37.5346 10.99 36.4611 11.0845 35.7408 10.4806L26.8234 3.00571C26.1031 2.4019 26.0087 1.32847 26.6125 0.608154C27.2163 -0.112158 28.2898 -0.206689 29.0101 0.397217L37.9273 7.87221C38.6477 8.47602 38.7422 9.54936 38.1384 10.2697Z" fill="#F7B422"></path>
                            <path d="M29.0101 0.397177C28.2898 -0.206631 27.2163 -0.112198 26.6125 0.608114C26.0087 1.32843 26.1031 2.40186 26.8234 3.00567L30.6025 6.17344C30.6525 6.10498 30.6789 6.01875 30.6718 5.92891C30.5229 4.03487 32.0058 3.72198 32.636 3.67413C32.7083 3.66866 32.7738 3.64024 32.8275 3.59717L29.0101 0.397177Z" fill="#FFCF67"></path>
                            <path d="M26.0213 26.3647C26.4686 25.831 26.3987 25.0356 25.8649 24.5883L14.658 15.1941C14.1243 14.7467 13.3289 14.8166 12.8816 15.3504C12.4342 15.8841 12.5041 16.6795 13.0379 17.1269L24.2448 26.5211C24.7785 26.9686 25.5738 26.8985 26.0213 26.3647Z" fill="#FFDD40"></path>
                            <path d="M14.658 15.1941C14.1243 14.7467 13.3289 14.8166 12.8816 15.3504C12.4342 15.8841 12.5041 16.6795 13.0379 17.1269L17.7561 21.0818C17.8102 21.0123 17.8387 20.9227 17.8294 20.8288C17.7175 19.6908 18.4431 19.3859 18.9923 19.3156C19.1367 19.2972 19.2442 19.197 19.285 19.0726L14.658 15.1941Z" fill="#FFB332"></path>
                            <path d="M21.9489 29.5833C22.5527 28.863 22.4583 27.7896 21.738 27.1858L12.8206 19.7109C12.1003 19.1071 11.0269 19.2015 10.4231 19.9218C9.81925 20.6421 9.91368 21.7156 10.634 22.3194L19.5514 29.7943C20.2718 30.3982 21.3451 30.3038 21.9489 29.5833Z" fill="#F7B422"></path>
                            <path d="M12.8206 19.7109C12.1003 19.1071 11.0269 19.2015 10.4231 19.9218C9.81925 20.6421 9.91368 21.7155 10.634 22.3194L14.2696 25.3669C14.299 25.3084 14.3132 25.242 14.3059 25.1738C14.1162 23.4059 15.4851 22.9779 16.1747 22.8744C16.276 22.8591 16.3607 22.8021 16.4151 22.724L12.8206 19.7109Z" fill="#FFCF67"></path>
                            <path d="M33.8744 32.5657H7.48603C5.10576 32.5657 3.1582 34.5132 3.1582 36.8935V37.9607C4.35088 40.0399 5.84765 41.9219 7.58642 43.5485H38.2021V36.8934C38.2022 34.5132 36.2547 32.5657 33.8744 32.5657Z" fill="#4F73F9"></path>
                            <path d="M16.5182 43.3618C15.4196 42.9188 15.4196 41.6563 15.4196 41.6563C15.4196 41.6563 15.4196 38.3604 15.4196 35.7237C15.4196 33.087 17.6169 32.5657 17.6169 32.5657H7.48603C5.10576 32.5657 3.1582 34.5132 3.1582 36.8935V37.9607C4.35088 40.0399 5.84765 41.9219 7.58642 43.5485H16.346C16.4445 43.4302 16.5182 43.3618 16.5182 43.3618Z" fill="#4F73F9"></path>
                            <path d="M39.5587 44.1865C39.3809 43.7055 38.9196 43.3618 38.3767 43.3618H7.38672C8.35273 44.2862 9.39609 45.1305 10.5061 45.8839H37.346C38.1169 45.3606 38.8556 44.7937 39.5587 44.1865Z" fill="#FFDD40"></path>
                            <path d="M16.5177 43.3618H7.38672C8.35273 44.2862 9.39609 45.1305 10.5061 45.8839H16.5177C15.004 44.7647 16.5177 43.3618 16.5177 43.3618Z" fill="#FFB332"></path>
                            <path d="M27.7812 22.1311L28.9511 23.1118C29.4041 23.4915 30.0854 23.4316 30.4651 22.9786L33.1041 19.8304C33.4839 19.3774 33.4239 18.696 32.9709 18.3164L31.8011 17.3357L27.7812 22.1311Z" fill="#FFDD40"></path>
                            <path d="M42.9476 26.544C42.3323 26.0282 41.4154 26.109 40.8997 26.7242L38.6151 29.4497C38.0993 30.065 38.18 30.9819 38.7953 31.4976C39.4106 32.0135 40.3275 31.9328 40.8432 31.3175L43.1278 28.592C43.6435 27.9767 43.5629 27.0599 42.9476 26.544Z" fill="#FFDD40"></path>
                            <path d="M40.6759 29.2101C40.2004 28.8114 40.015 28.1961 40.141 27.6294L38.6151 29.4497C38.0993 30.065 38.18 30.9819 38.7953 31.4977C39.4106 32.0135 40.3275 31.9328 40.8432 31.3175L42.4456 29.406C41.8876 29.6942 41.1875 29.6389 40.6759 29.2101Z" fill="#FFB332"></path>
                            <path d="M31.8733 17.3955C29.5802 17.8629 27.9133 19.8515 27.8535 22.191L30.002 19.9187L31.8733 17.3955Z" fill="#FFDD40"></path>
                        </svg>
                        <p>Legal</p>
                    </li>
                    <li>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#0F358F"></path>
                            <path d="M50 24.9999C50 22.9664 49.7572 20.9896 49.299 19.0969L42.4805 12.2783L7.51953 14.5055L10.5677 17.5536L9.02061 18.4188L13.0373 22.4355V35.8839L8.01045 39.6875L17.0233 48.7004C19.5284 49.5432 22.2108 49.9999 25 49.9999C38.8071 49.9999 50 38.807 50 24.9999Z" fill="#1E45A3"></path>
                            <path d="M10.0156 15.5869H16.0605V34.9308H10.0156V15.5869Z" fill="#EFB73D"></path>
                            <path d="M33.9414 15.5869H39.9863V34.9308H33.9414V15.5869Z" fill="#D59C21"></path>
                            <path d="M21.9775 15.5869H25.1665V34.9308H21.9775V15.5869Z" fill="#EFB73D"></path>
                            <path d="M25 15.5869H28.0225V34.9308H25V15.5869Z" fill="#D59C21"></path>
                            <path d="M8.00977 34.374H25.1657V39.6872H8.00977V34.374Z" fill="#FFCF67"></path>
                            <path d="M25 34.374H41.9895V39.6872H25V34.374Z" fill="#F7B422"></path>
                            <path d="M9.02051 13.3604H25.1664V18.419H9.02051V13.3604Z" fill="#FFCF67"></path>
                            <path d="M25 13.3604H40.9794V18.419H25V13.3604Z" fill="#F7B422"></path>
                            <path d="M25 4.4834L7.51953 12.2782V14.5054H25.1665V4.55762L25 4.4834Z" fill="#EFB73D"></path>
                            <path d="M25 14.5054H42.4805V12.2782L25 4.4834V14.5054Z" fill="#D59C21"></path>
                            <path d="M20.3232 9.25586H25.1666V12.6919H20.3232V9.25586Z" fill="#FFCF67"></path>
                            <path d="M25 9.25586H29.6769V12.6919H25V9.25586Z" fill="#F7B422"></path>
                        </svg>
                        <p>Government</p>
                    </li>
                    <li>
                        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.9258 2.14844C10.7119 2.14844 0 12.8604 0 26.0742C0 31.4075 1.7458 36.3326 4.696 40.3109L5.07891 40.4086H41.4062V39.9414C41.4062 39.4029 40.9682 38.9648 40.4297 38.9648H38.2104V37.0475C38.2104 36.8611 38.159 36.6807 38.063 36.5234H42.4932V21.1914H43.3302C45.0764 21.1914 46.4971 22.612 46.4971 24.3583V33.728L46.5947 33.7441C47.4095 31.3364 47.8516 28.757 47.8516 26.0742C47.8516 12.8604 37.1396 2.14844 23.9258 2.14844Z" fill="#0F358F"></path>
                            <path d="M42.3957 21.0938H28.4181V33.2031C28.4181 33.6877 28.0238 34.082 27.5392 34.082H20.3232V36.1328H24.1123C24.5852 36.1328 24.9753 36.4935 25.0223 36.9541C25.0254 36.9848 25.027 37.0159 25.027 37.0475V38.9648V39.0625H31.8978L31.7384 38.9648V37.0475C31.7384 36.5432 32.1486 36.1328 32.653 36.1328H37.1981C37.5075 36.1328 37.7811 36.2876 37.9467 36.5234H42.4934V21.9218L42.3957 21.0938Z" fill="#F7B422"></path>
                            <path d="M19.8242 34.082H24.0234V36.1328H19.8242V34.082Z" fill="#FFCF67"></path>
                            <path d="M43.3305 21.0938H42.3958V36.4258H37.8672C38.0191 36.5892 38.1129 36.8073 38.1129 37.0475V39.0625H40.4299C40.9146 39.0625 41.3088 39.4568 41.3088 39.9414V40.4086H42.7729L43.1558 40.3109C44.622 38.3338 45.7902 36.1228 46.595 33.744V24.3583C46.595 22.5554 45.1335 21.0938 43.3305 21.0938Z" fill="#FFCF67"></path>
                            <path d="M38.2104 39.0625V37.0475C38.2104 36.4884 37.7572 36.0352 37.1981 36.0352H32.6529C32.0938 36.0352 31.6406 36.4884 31.6406 37.0475V39.0625H38.2104Z" fill="#FED2A4"></path>
                            <path d="M33.6914 38.9648V37.0475C33.6914 36.4884 34.1446 36.0352 34.7037 36.0352H32.6529C32.0938 36.0352 31.6406 36.4884 31.6406 37.0475V38.9648H33.6914Z" fill="#FFBD86"></path>
                            <path d="M22.0703 38.0859C22.5549 38.0859 22.9492 38.4803 22.9492 38.9648V39.0625H25.1245V37.0475C25.1245 36.4884 24.6713 36.0352 24.1122 36.0352H19.567C19.0079 36.0352 18.5547 36.4884 18.5547 37.0475V38.0859H22.0703Z" fill="#FED2A4"></path>
                            <path d="M20.6055 37.9883V37.0475C20.6055 36.4884 21.0587 36.0352 21.6178 36.0352H19.567C19.0079 36.0352 18.5547 36.4884 18.5547 37.0475V37.9883H20.6055Z" fill="#FFBD86"></path>
                            <path d="M40.4297 38.9648H22.9492V40.4086H41.4062V39.9414C41.4062 39.4021 40.969 38.9648 40.4297 38.9648Z" fill="white"></path>
                            <path d="M23.0469 38.9648V40.4086H25.3906V39.9414C25.3906 39.4021 25.8278 38.9648 26.3672 38.9648H23.0469Z" fill="#F3F0FF"></path>
                            <path d="M3.51562 34.082C3.03105 34.082 2.63672 33.6877 2.63672 33.2031V19.5312C2.63672 19.0467 3.03105 18.6523 3.51562 18.6523H27.5391C28.0237 18.6523 28.418 19.0467 28.418 19.5312V33.2031C28.418 33.6877 28.0237 34.082 27.5391 34.082H3.51562Z" fill="#A79BC6"></path>
                            <path d="M37.5187 19.7715C36.8352 20.1671 36.0428 20.3948 35.1978 20.3948H30.4506C29.7221 20.3948 29.0323 20.226 28.418 19.9261V24.8409L32.6148 22.8532C32.7474 22.7904 32.9011 22.7906 33.0335 22.8535L37.7107 25.0777C38.0707 25.2489 38.4729 24.94 38.4006 24.5481L37.5187 19.7715Z" fill="#D59202"></path>
                            <path d="M35.1977 20.3946H30.4505C30.2925 20.3946 30.1364 20.3865 29.9824 20.3711L32.4012 24.5605C32.5892 24.886 33.059 24.886 33.2469 24.5605L35.6656 20.3711C35.5117 20.3866 35.3556 20.3946 35.1977 20.3946Z" fill="#F6A96C"></path>
                            <path d="M34.0104 0H31.6367C27.267 0 23.7246 3.54238 23.7246 7.91211V10.56C24.0704 10.3478 24.4769 10.2252 24.9114 10.2252H25.8003V9.57373C25.8003 7.44619 27.5312 5.71533 29.6587 5.71533H35.9883C38.1158 5.71533 39.8468 7.44619 39.8468 9.57373V10.2252H40.7356C41.1702 10.2252 41.5767 10.3479 41.9225 10.56V7.91211C41.9225 3.54238 38.3801 0 34.0104 0Z" fill="#A79BC6"></path>
                            <path d="M24.8333 10.1295V8.8375C24.8333 6.21934 26.3553 3.95693 28.5626 2.88584C29.9487 1.2042 32.0145 0.103613 34.3407 0.00830078C34.2313 0.00380859 34.1218 0 34.0113 0H31.6377C27.268 0 23.7256 3.54238 23.7256 7.91211V10.4459C24.0534 10.2562 24.4306 10.1427 24.8333 10.1295Z" fill="#9182B0"></path>
                            <path d="M34.2036 2.22559H31.4448C27.7933 2.22559 24.833 5.18584 24.833 8.8374V10.2271C24.8594 10.2262 24.8856 10.225 24.9122 10.225H25.8011V9.57353C25.8011 7.446 27.5319 5.71514 29.6595 5.71514H35.9891C38.1166 5.71514 39.8476 7.446 39.8476 9.57353V10.225H40.7364C40.763 10.225 40.7893 10.2261 40.8155 10.2271V8.8374C40.8154 5.18584 37.8552 2.22559 34.2036 2.22559Z" fill="#022068"></path>
                            <path d="M40.7357 10.1279H39.8469V15.7455C39.8469 16.1748 39.7872 16.5901 39.6777 16.9851H40.6362L41.308 17.3832L42.1011 16.5524C42.101 16.5524 42.101 16.5525 42.1009 16.5525C42.7106 16.1229 43.1093 15.414 43.1093 14.6114V12.5016C43.1094 11.1906 42.0466 10.1279 40.7357 10.1279Z" fill="#7A6D98"></path>
                            <path d="M24.9121 10.1273H25.7033V9.57354C25.7033 7.38867 27.4745 5.61748 29.6594 5.61748H29.7427C30.7723 3.7751 32.6574 2.47578 34.8608 2.2583C34.6446 2.23691 34.4255 2.22559 34.2036 2.22559H31.4448C27.7933 2.22559 24.833 5.18584 24.833 8.8374V10.1294C24.8594 10.1284 24.8855 10.1273 24.9121 10.1273Z" fill="#183B90"></path>
                            <path d="M25.8006 15.7445V10.127H24.9117C23.6008 10.127 22.5381 11.1896 22.5381 12.5006V14.6104C22.5381 15.9214 23.6008 16.9841 24.9117 16.9841H25.9697C25.8603 16.5893 25.8006 16.1738 25.8006 15.7445Z" fill="#7A6D98"></path>
                            <path d="M25.8612 16.9595C24.7058 16.7995 23.8151 15.8109 23.8151 14.6114V12.5016C23.8151 11.3571 24.6261 10.4046 25.704 10.1805V10.1279H24.9127C23.6018 10.1279 22.5391 11.1906 22.5391 12.5016V14.6114C22.5391 15.9224 23.6018 16.9851 24.9127 16.9851H25.8685C25.8661 16.9765 25.8635 16.9681 25.8612 16.9595Z" fill="#685E87"></path>
                            <path d="M35.1977 20.4919H30.4504C27.8285 20.4919 25.7031 18.3665 25.7031 15.7446V9.57324C25.7031 7.38838 27.4743 5.61719 29.6592 5.61719H35.9889C38.1737 5.61719 39.9449 7.38838 39.9449 9.57324V15.7446C39.9449 18.3665 37.8194 20.4919 35.1977 20.4919Z" fill="#FFCF67"></path>
                            <path d="M31.1336 19.8273C29.7416 18.9998 28.8084 17.4815 28.8084 15.7447V9.57324C28.8084 7.38838 30.5796 5.61719 32.7645 5.61719H29.6592C27.4743 5.61719 25.7031 7.38838 25.7031 9.57324V15.7446C25.7031 16.7962 26.0456 17.7675 26.6244 18.5542H27.5389C28.0782 18.5542 28.5154 18.9914 28.5154 19.5308V20.0794C29.1065 20.3437 29.761 20.4919 30.4504 20.4919H30.8229C30.8615 20.2392 30.972 20.0106 31.1336 19.8273Z" fill="#F7B422"></path>
                            <path d="M29.387 12.5807C30.0425 12.5807 30.5738 12.0493 30.5738 11.3938C30.5738 10.7384 30.0425 10.207 29.387 10.207C28.7316 10.207 28.2002 10.7384 28.2002 11.3938C28.2002 12.0493 28.7316 12.5807 29.387 12.5807Z" fill="#D59202"></path>
                            <path d="M36.261 12.5807C36.9165 12.5807 37.4479 12.0493 37.4479 11.3938C37.4479 10.7384 36.9165 10.207 36.261 10.207C35.6056 10.207 35.0742 10.7384 35.0742 11.3938C35.0742 12.0493 35.6056 12.5807 36.261 12.5807Z" fill="#D59202"></path>
                            <path d="M32.744 14.9744C32.5965 14.9744 32.4479 14.9301 32.3188 14.838L31.2111 14.0468C30.9145 13.835 30.8188 13.4372 30.9866 13.1137L32.0943 10.9775C32.2806 10.6183 32.7227 10.4782 33.0816 10.6644C33.4408 10.8506 33.5808 11.2927 33.3947 11.6517L32.5793 13.224L33.1702 13.646C33.4993 13.8812 33.5755 14.3386 33.3404 14.6677C33.1976 14.8679 32.9725 14.9744 32.744 14.9744Z" fill="#D59202"></path>
                            <path d="M33.2596 18.5055H32.3893C30.7638 18.5055 29.4414 17.1831 29.4414 15.5576C29.4414 15.1531 29.7692 14.8252 30.1738 14.8252C30.5784 14.8252 30.9062 15.1531 30.9062 15.5576C30.9062 16.3754 31.5716 17.0406 32.3893 17.0406H33.2596C34.0772 17.0406 34.7426 16.3754 34.7426 15.5576C34.7426 15.1531 35.0704 14.8252 35.475 14.8252C35.8796 14.8252 36.2074 15.1531 36.2074 15.5576C36.2074 17.1831 34.8851 18.5055 33.2596 18.5055Z" fill="#D59202"></path>
                            <path d="M27.5391 34.1797H3.51562C2.97627 34.1797 2.53906 33.7425 2.53906 33.2031V19.5312C2.53906 18.9919 2.97627 18.5547 3.51562 18.5547H27.5391C28.0784 18.5547 28.5156 18.9919 28.5156 19.5312V33.2031C28.5156 33.7425 28.0784 34.1797 27.5391 34.1797Z" fill="#022068"></path>
                            <path d="M5.76172 33.2031V19.5312C5.76172 18.9919 6.19893 18.5547 6.73828 18.5547H3.51562C2.97627 18.5547 2.53906 18.9919 2.53906 19.5312V33.2031C2.53906 33.7425 2.97627 34.1797 3.51562 34.1797H6.73828C6.19893 34.1797 5.76172 33.7425 5.76172 33.2031Z" fill="#183B90"></path>
                            <path d="M20.6211 10.2168C20.6211 6.24904 17.3572 3.04357 13.3679 3.13879C9.62755 3.22804 6.57218 6.26711 6.46397 10.0069C6.36993 13.259 8.46983 16.0344 11.3927 16.9642C11.6577 17.0485 11.878 17.2349 11.9953 17.487L13.2754 20.2409C13.3805 20.4669 13.7017 20.4669 13.8067 20.2409L15.0868 17.4871C15.2008 17.2419 15.4104 17.0521 15.6683 16.9709C18.5389 16.0678 20.6211 13.3858 20.6211 10.2168Z" fill="#F7B422"></path>
                            <path d="M14.0313 16.4985C13.9141 16.2464 13.6937 16.06 13.4286 15.9757C10.5058 15.0459 8.40587 12.2704 8.49992 9.01836C8.56662 6.71445 9.75265 4.67783 11.5274 3.43164C8.67257 4.28555 6.55373 6.90391 6.46398 10.0063C6.36984 13.2585 8.46984 16.0339 11.3927 16.9637C11.6577 17.0479 11.878 17.2344 11.9953 17.4865L13.2754 20.2404C13.3805 20.4664 13.7017 20.4664 13.8068 20.2404L14.7887 18.1279L14.0313 16.4985Z" fill="#F7B422"></path>
                            <path d="M19.596 24.1211H11.458C11.1884 24.1211 10.9697 24.3397 10.9697 24.6094V29.1016C10.9697 29.3712 11.1884 29.5898 11.458 29.5898H13.4762V26.8555C13.4762 26.64 13.6514 26.4648 13.8668 26.4648H17.1871C17.4025 26.4648 17.5777 26.64 17.5777 26.8555V29.5898H19.596C19.8656 29.5898 20.0843 29.3712 20.0843 29.1016V24.6094C20.0843 24.3397 19.8656 24.1211 19.596 24.1211Z" fill="#9182B0"></path>
                            <path d="M17.6758 38.0859V26.8555C17.6758 26.5858 17.4571 26.3672 17.1875 26.3672H13.8672C13.5976 26.3672 13.3789 26.5858 13.3789 26.8555V38.0859H17.6758Z" fill="#7A6D98"></path>
                            <path d="M15.8203 26.3672H13.8672C13.5976 26.3672 13.3789 26.5858 13.3789 26.8555V38.0859H15.332V26.8555C15.332 26.5858 15.5507 26.3672 15.8203 26.3672Z" fill="#685E87"></path>
                            <path d="M23.0469 40.4086V38.9648C23.0469 38.4255 22.6097 37.9883 22.0703 37.9883H8.98438C8.44502 37.9883 8.00781 38.4255 8.00781 38.9648V40.4086H23.0469Z" fill="#A79BC6"></path>
                            <path d="M11.9141 40.3109V38.9648C11.9141 38.4255 12.3513 37.9883 12.8906 37.9883H8.98438C8.44502 37.9883 8.00781 38.4255 8.00781 38.9648V40.3109H11.9141Z" fill="#9182B0"></path>
                            <path d="M8.65645 43.9453L8.12988 44.043C12.3439 47.7504 17.8716 50 23.9254 50C29.9792 50 35.5069 47.7504 39.7209 44.043L39.1943 43.9453H8.65645Z" fill="#1E45A3"></path>
                            <path d="M4.69629 40.3105C5.70674 41.673 6.85869 42.924 8.13018 44.0426H39.722C40.9935 42.924 42.1454 41.673 43.1559 40.3105H4.69629Z" fill="#022068"></path>
                            <path d="M14.5258 41.6022C14.078 41.1882 13.6464 40.757 13.231 40.3105H4.69629C5.70674 41.673 6.85869 42.924 8.13018 44.0426H39.722C40.4506 43.4016 41.1385 42.7158 41.7844 41.9918H15.5218C15.1526 41.9918 14.7968 41.8528 14.5258 41.6022Z" fill="#183B90"></path>
                            <path d="M13.2443 9.17646C13.2443 8.18848 12.4406 7.38477 11.4526 7.38477H9.92969C9.5252 7.38477 9.19727 7.7127 9.19727 8.11719C9.19727 8.52168 9.5252 8.84961 9.92969 8.84961H11.4527C11.6329 8.84961 11.7796 8.99619 11.7796 9.17646C11.7796 9.26064 11.7477 9.34063 11.6897 9.40166L9.39893 11.8118C9.19717 12.0241 9.14121 12.3361 9.25674 12.6053C9.37227 12.8743 9.63691 13.0488 9.92979 13.0488H12.512C12.9165 13.0488 13.2444 12.7209 13.2444 12.3164C13.2444 11.9119 12.9165 11.584 12.512 11.584H11.6364L12.7515 10.4107C13.0693 10.0763 13.2443 9.63799 13.2443 9.17646Z" fill="white"></path>
                            <path d="M17.1506 7.38477C16.7461 7.38477 16.4182 7.7127 16.4182 8.11719V9.71426H15.3008V8.11719C15.3008 7.7127 14.9729 7.38477 14.5684 7.38477C14.1639 7.38477 13.8359 7.7127 13.8359 8.11719V10.4467C13.8359 10.8512 14.1639 11.1791 14.5684 11.1791H16.4182V12.3164C16.4182 12.7209 16.7461 13.0488 17.1506 13.0488C17.5551 13.0488 17.883 12.7209 17.883 12.3164V8.11719C17.883 7.7127 17.5551 7.38477 17.1506 7.38477Z" fill="white"></path>
                            <path d="M40.7365 16.9843H40.637V18.5931C40.637 19.2799 40.0782 19.8387 39.3914 19.8387H34.3926C34.6099 20.0572 34.7443 20.358 34.7443 20.6897C34.7443 20.914 34.6818 21.1233 34.5749 21.3035H39.3914C40.8859 21.3035 42.1019 20.0876 42.1019 18.5931V16.5518C41.7156 16.8238 41.2449 16.9843 40.7365 16.9843Z" fill="#9182B0"></path>
                            <path d="M33.5363 21.9957H32.1121C31.3911 21.9957 30.8066 21.4112 30.8066 20.6902C30.8066 19.9692 31.3911 19.3848 32.1121 19.3848H33.5363C34.2573 19.3848 34.8418 19.9692 34.8418 20.6902C34.8418 21.4112 34.2573 21.9957 33.5363 21.9957Z" fill="#7A6D98"></path>
                            <path d="M32.1953 20.6902C32.1953 19.9692 32.7798 19.3848 33.5008 19.3848H32.1121C31.3911 19.3848 30.8066 19.9692 30.8066 20.6902C30.8066 21.4112 31.3911 21.9957 32.1121 21.9957H33.5008C32.7798 21.9957 32.1953 21.4112 32.1953 20.6902Z" fill="#685E87"></path>
                        </svg>
                        <p>Education</p>
                    </li>
                    <li>
                        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.1498 42.2681L44.3963 38.4671C46.5893 34.8525 47.852 30.6109 47.852 26.0744C47.852 12.8604 37.1399 2.14844 23.926 2.14844C10.712 2.14844 0 12.8604 0 26.0744C0 32.3207 2.39453 38.0071 6.31455 42.2681H32.1498Z" fill="#0F358F"></path>
                            <path d="M30.42 5.24678C30.4758 4.24805 31.4803 3.51953 32.4606 3.80381C32.6312 3.85332 32.8138 3.79502 32.9225 3.65449C33.457 2.96357 34.2941 2.51855 35.2354 2.51855C36.5358 2.51855 37.6373 3.36807 38.0165 4.54238C38.0793 4.73691 38.2733 4.85176 38.4765 4.82861C39.1663 4.75 39.8351 5.08701 40.1894 5.66846C40.3099 5.86621 40.5644 5.92803 40.7669 5.81572C41.007 5.68262 41.2916 5.62012 41.593 5.65664C42.2099 5.73135 42.7055 6.23223 42.774 6.8498C42.8651 7.67012 42.2255 8.36455 41.4235 8.36455H29.9616C29.2007 8.36455 28.5565 7.74189 28.5695 6.98105C28.583 6.18984 29.2548 5.61523 29.9854 5.64648C30.2137 5.65645 30.4072 5.475 30.42 5.24678Z" fill="#8EA6FF"></path>
                            <path d="M23.7106 11.5419C23.7967 10.0011 25.3463 8.87715 26.8588 9.31582C27.122 9.39219 27.4036 9.30215 27.5713 9.08545C28.396 8.01953 29.6874 7.33301 31.1395 7.33301C33.1458 7.33301 34.845 8.64365 35.4301 10.4553C35.527 10.7554 35.8263 10.9325 36.1397 10.8969C37.2038 10.7756 38.2356 11.2955 38.7821 12.1926C38.9681 12.4978 39.3606 12.5931 39.6731 12.4198C40.0436 12.2145 40.4826 12.1181 40.9476 12.1744C41.8993 12.2896 42.6638 13.0623 42.7696 14.0151C42.9103 15.2807 41.9233 16.3521 40.6862 16.3521H23.0034C21.8296 16.3521 20.8357 15.3914 20.8558 14.2178C20.8767 12.9971 21.913 12.1106 23.04 12.1589C23.3924 12.1737 23.691 11.8938 23.7106 11.5419Z" fill="#5A7BF4"></path>
                            <path d="M18.7838 23.8765C18.6779 22.9236 17.9134 22.151 16.9617 22.0357C16.4967 21.9794 16.0577 22.0758 15.6873 22.2812C15.3748 22.4544 14.9822 22.3591 14.7963 22.0539C14.2497 21.1568 13.218 20.6369 12.1538 20.7582C11.8404 20.7939 11.5412 20.6167 11.4442 20.3166C10.8592 18.505 9.15996 17.1943 7.15361 17.1943C5.70156 17.1943 4.41016 17.8809 3.58545 18.9468C3.41777 19.1635 3.13613 19.2534 2.87295 19.1771C2.18721 18.9782 1.49424 19.1014 0.933008 19.4382C0.326074 21.5452 0 23.7712 0 26.0735C0 26.1202 0.00136721 26.1666 0.00166018 26.2133H16.7006C17.9375 26.2134 18.9244 25.142 18.7838 23.8765Z" fill="#8EA6FF"></path>
                            <path d="M35.2477 36.4275H35.0984V47.2346C38.9753 45.1834 42.2206 42.0988 44.4672 38.3469V21.0543L29.3115 13.8398V34.0395H35.2478C35.9062 34.0395 36.4418 34.5751 36.4418 35.2335C36.4418 35.8919 35.9061 36.4275 35.2477 36.4275Z" fill="#5C7DCA"></path>
                            <path d="M29.3115 34.0395H35.2478C35.2491 34.0395 35.2505 34.0396 35.252 34.0396V33.8901C34.0614 33.5358 34.1313 32.2263 34.1313 32.2263C34.1313 32.2263 34.1313 20.1657 34.1313 18.7985C34.1313 17.4313 35.3403 17.5348 35.3403 17.5348L35.1931 16.6395L29.3116 13.8398V34.0395H29.3115Z" fill="#022068"></path>
                            <path d="M6.22363 42.1702C9.92705 46.2408 15.0156 49.0278 20.7442 49.7892V36.4777H20.7443H26.3643V22.9443H6.22363V42.1702Z" fill="#5C7DCA"></path>
                            <path d="M11.4998 26.6917C11.4998 24.6221 13.1775 22.9443 15.2472 22.9443H6.22363V42.1702C7.76113 43.8602 9.53809 45.3279 11.4998 46.5224V26.6917Z" fill="#5C7DCA"></path>
                            <path d="M8.48047 11.8418H24.1064V23.0434H8.48047V11.8418Z" fill="#5C7DCA"></path>
                            <path d="M12.6824 20.3951V14.49C12.6824 13.0274 13.8681 11.8418 15.3307 11.8418H8.48047V23.0434H15.3307C13.8681 23.0434 12.6824 21.8577 12.6824 20.3951Z" fill="#5C7DCA"></path>
                            <path d="M16.2941 4.57373C15.882 4.57373 15.5479 4.23965 15.5479 3.82744V0.746289C15.5479 0.33418 15.8819 0 16.2941 0C16.7063 0 17.0404 0.334082 17.0404 0.746289V3.82744C17.0403 4.23955 16.7063 4.57373 16.2941 4.57373Z" fill="#DAE2FE"></path>
                            <path d="M9.20918 25.5293C8.79707 25.5293 8.46289 25.8634 8.46289 26.2756V44.3294C8.94453 44.7378 9.44219 45.1276 9.95547 45.4975V26.2756C9.95547 25.8635 9.62139 25.5293 9.20918 25.5293Z" fill="#8EA6FF"></path>
                            <path d="M12.0432 25.5293C11.6311 25.5293 11.2969 25.8634 11.2969 26.2756V46.3967C11.783 46.6994 12.2808 46.9852 12.7895 47.2532V26.2757C12.7895 25.8635 12.4554 25.5293 12.0432 25.5293Z" fill="#8EA6FF"></path>
                            <path d="M14.8771 25.5293C14.465 25.5293 14.1309 25.8634 14.1309 26.2756V47.9086C14.6198 48.1283 15.1177 48.3318 15.6234 48.5189V26.2756C15.6234 25.8635 15.2893 25.5293 14.8771 25.5293Z" fill="#8EA6FF"></path>
                            <path d="M17.7111 25.5293C17.299 25.5293 16.9648 25.8634 16.9648 26.2756V48.9698C17.456 49.1189 17.9534 49.2533 18.4574 49.3712V26.2756C18.4573 25.8635 18.1232 25.5293 17.7111 25.5293Z" fill="#8EA6FF"></path>
                            <path d="M20.5451 32.9947C20.133 32.9947 19.7988 32.6606 19.7988 32.2484V26.2756C19.7988 25.8635 20.1329 25.5293 20.5451 25.5293C20.9572 25.5293 21.2914 25.8634 21.2914 26.2756V32.2484C21.2914 32.6606 20.9573 32.9947 20.5451 32.9947Z" fill="#8EA6FF"></path>
                            <path d="M12.0432 21.2524C11.6311 21.2524 11.2969 20.9184 11.2969 20.5062V14.5637C11.2969 14.1516 11.631 13.8174 12.0432 13.8174C12.4554 13.8174 12.7895 14.1515 12.7895 14.5637V20.5062C12.7894 20.9183 12.4553 21.2524 12.0432 21.2524Z" fill="#8EA6FF"></path>
                            <path d="M14.8771 21.2524C14.465 21.2524 14.1309 20.9184 14.1309 20.5062V14.5637C14.1309 14.1516 14.4649 13.8174 14.8771 13.8174C15.2893 13.8174 15.6234 14.1515 15.6234 14.5637V20.5062C15.6233 20.9183 15.2893 21.2524 14.8771 21.2524Z" fill="#8EA6FF"></path>
                            <path d="M17.7111 21.2524C17.299 21.2524 16.9648 20.9184 16.9648 20.5062V14.5637C16.9648 14.1516 17.2989 13.8174 17.7111 13.8174C18.1232 13.8174 18.4574 14.1515 18.4574 14.5637V20.5062C18.4573 20.9183 18.1232 21.2524 17.7111 21.2524Z" fill="#8EA6FF"></path>
                            <path d="M20.5451 21.2524C20.133 21.2524 19.7988 20.9184 19.7988 20.5062V14.5637C19.7988 14.1516 20.1329 13.8174 20.5451 13.8174C20.9572 13.8174 21.2914 14.1515 21.2914 14.5637V20.5062C21.2914 20.9183 20.9572 21.2524 20.5451 21.2524Z" fill="#A8B7EF"></path>
                            <path d="M20.5451 21.2524C20.133 21.2524 19.7988 20.9184 19.7988 20.5062V14.5637C19.7988 14.1516 20.1329 13.8174 20.5451 13.8174C20.9572 13.8174 21.2914 14.1515 21.2914 14.5637V20.5062C21.2914 20.9183 20.9573 21.2524 20.5451 21.2524Z" fill="#8EA6FF"></path>
                            <path d="M23.3791 32.9947C22.967 32.9947 22.6328 32.6606 22.6328 32.2484V26.2756C22.6328 25.8635 22.9669 25.5293 23.3791 25.5293C23.7913 25.5293 24.1254 25.8634 24.1254 26.2756V32.2484C24.1254 32.6606 23.7912 32.9947 23.3791 32.9947Z" fill="#8EA6FF"></path>
                            <path d="M12.2012 4.38184H20.3869V11.8424H12.2012V4.38184Z" fill="#5C7DCA"></path>
                            <path d="M15.6219 9.58477V6.63945C15.6219 5.39258 16.6326 4.38184 17.8795 4.38184H12.2012V11.8424H17.8795C16.6326 11.8424 15.6219 10.8316 15.6219 9.58477Z" fill="#5C7DCA"></path>
                            <path d="M20.5449 36.4775V49.7611C21.6496 49.9174 22.7779 50 23.9257 50C28.0232 50 31.8798 48.9691 35.2514 47.1538V36.4775H20.5449Z" fill="#FFCF67"></path>
                            <path d="M25.8354 46.6266V39.8509C25.8354 37.9878 27.3457 36.4775 29.2088 36.4775H20.5449V49.7611C21.6496 49.9175 22.7779 50.0001 23.9257 50.0001C25.2395 50.0001 26.5283 49.8936 27.7845 49.6897C26.6329 49.1537 25.8354 47.9797 25.8354 46.6266Z" fill="#F7B422"></path>
                            <path d="M41.2272 24.9223H32.4533C32.0412 24.9223 31.707 24.5882 31.707 24.176C31.707 23.7639 32.0411 23.4297 32.4533 23.4297H41.2272C41.6395 23.4297 41.9735 23.7638 41.9735 24.176C41.9734 24.5882 41.6394 24.9223 41.2272 24.9223Z" fill="#8EA6FF"></path>
                            <path d="M41.2272 28.0668H32.4533C32.0412 28.0668 31.707 27.7327 31.707 27.3205C31.707 26.9084 32.0411 26.5742 32.4533 26.5742H41.2272C41.6395 26.5742 41.9735 26.9083 41.9735 27.3205C41.9734 27.7327 41.6394 28.0668 41.2272 28.0668Z" fill="#8EA6FF"></path>
                            <path d="M41.2272 31.2113H32.4533C32.0412 31.2113 31.707 30.8772 31.707 30.465C31.707 30.0528 32.0411 29.7188 32.4533 29.7188H41.2272C41.6395 29.7188 41.9735 30.0528 41.9735 30.465C41.9735 30.8772 41.6394 31.2113 41.2272 31.2113Z" fill="#8EA6FF"></path>
                            <path d="M41.2269 34.3559H38.3371C37.925 34.3559 37.5908 34.0218 37.5908 33.6096C37.5908 33.1974 37.9249 32.8633 38.3371 32.8633H41.2269C41.6391 32.8633 41.9731 33.1974 41.9731 33.6096C41.9731 34.0218 41.639 34.3559 41.2269 34.3559Z" fill="#8EA6FF"></path>
                            <path d="M41.2269 37.5004H38.3371C37.925 37.5004 37.5908 37.1663 37.5908 36.7541C37.5908 36.3419 37.9249 36.0078 38.3371 36.0078H41.2269C41.6391 36.0078 41.9731 36.3419 41.9731 36.7541C41.9731 37.1663 41.639 37.5004 41.2269 37.5004Z" fill="#8EA6FF"></path>
                            <path d="M41.2262 40.6449H37.7395C37.3273 40.6449 36.9932 40.3108 36.9932 39.8986C36.9932 39.4865 37.3272 39.1523 37.7395 39.1523H41.2262C41.6384 39.1523 41.9725 39.4864 41.9725 39.8986C41.9724 40.3107 41.6383 40.6449 41.2262 40.6449Z" fill="#8EA6FF"></path>
                            <path d="M41.2263 42.2969H37.7395C37.3273 42.2969 36.9932 42.631 36.9932 43.0432C36.9932 43.4554 37.3272 43.7895 37.7395 43.7895H40.0077C40.5164 43.3274 41.0039 42.8427 41.4712 42.3389C41.3943 42.3121 41.3121 42.2969 41.2263 42.2969Z" fill="#8EA6FF"></path>
                            <path d="M44.5572 21.8461C44.4496 21.8461 44.3404 21.8228 44.2368 21.7733L28.821 14.429C28.4488 14.2518 28.291 13.8064 28.4682 13.4343C28.6455 13.0622 29.0909 12.9042 29.463 13.0815L44.8788 20.4259C45.2509 20.6031 45.4088 21.0485 45.2315 21.4206C45.1035 21.6891 44.836 21.8461 44.5572 21.8461Z" fill="#022068"></path>
                            <path d="M23.6467 41.0748C23.2346 41.0748 22.9004 40.7407 22.9004 40.3285V39.4182C22.9004 39.0061 23.2345 38.6719 23.6467 38.6719C24.0588 38.6719 24.393 39.006 24.393 39.4182V40.3285C24.393 40.7406 24.0588 41.0748 23.6467 41.0748Z" fill="#FF9100"></path>
                            <path d="M26.4816 41.0748C26.0695 41.0748 25.7354 40.7407 25.7354 40.3285V39.4182C25.7354 39.0061 26.0694 38.6719 26.4816 38.6719C26.8938 38.6719 27.2279 39.006 27.2279 39.4182V40.3285C27.2279 40.7406 26.8938 41.0748 26.4816 41.0748Z" fill="#FF9100"></path>
                            <path d="M29.3156 41.0748C28.9035 41.0748 28.5693 40.7407 28.5693 40.3285V39.4182C28.5693 39.0061 28.9034 38.6719 29.3156 38.6719C29.7278 38.6719 30.0619 39.006 30.0619 39.4182V40.3285C30.0619 40.7406 29.7278 41.0748 29.3156 41.0748Z" fill="#FF9100"></path>
                            <path d="M32.1496 41.0748C31.7375 41.0748 31.4033 40.7407 31.4033 40.3285V39.4182C31.4033 39.0061 31.7374 38.6719 32.1496 38.6719C32.5617 38.6719 32.8959 39.006 32.8959 39.4182V40.3285C32.8958 40.7406 32.5617 41.0748 32.1496 41.0748Z" fill="#FF9100"></path>
                            <path d="M23.6467 44.9352C23.2346 44.9352 22.9004 44.6011 22.9004 44.1889V43.2785C22.9004 42.8664 23.2345 42.5322 23.6467 42.5322C24.0588 42.5322 24.393 42.8663 24.393 43.2785V44.1889C24.393 44.6011 24.0588 44.9352 23.6467 44.9352Z" fill="#FF9100"></path>
                            <path d="M26.4816 44.9352C26.0695 44.9352 25.7354 44.6011 25.7354 44.1889V43.2785C25.7354 42.8664 26.0694 42.5322 26.4816 42.5322C26.8938 42.5322 27.2279 42.8663 27.2279 43.2785V44.1889C27.2279 44.6011 26.8938 44.9352 26.4816 44.9352Z" fill="#FF9100"></path>
                            <path d="M29.3156 44.9352C28.9035 44.9352 28.5693 44.6011 28.5693 44.1889V43.2785C28.5693 42.8664 28.9034 42.5322 29.3156 42.5322C29.7278 42.5322 30.0619 42.8663 30.0619 43.2785V44.1889C30.0619 44.6011 29.7278 44.9352 29.3156 44.9352Z" fill="#FF9100"></path>
                            <path d="M32.1496 44.9352C31.7375 44.9352 31.4033 44.6011 31.4033 44.1889V43.2785C31.4033 42.8664 31.7374 42.5322 32.1496 42.5322C32.5617 42.5322 32.8959 42.8663 32.8959 43.2785V44.1889C32.8958 44.6011 32.5617 44.9352 32.1496 44.9352Z" fill="#FF9100"></path>
                            <path d="M23.6467 48.7955C23.2346 48.7955 22.9004 48.4614 22.9004 48.0492V47.1389C22.9004 46.7268 23.2345 46.3926 23.6467 46.3926C24.0588 46.3926 24.393 46.7267 24.393 47.1389V48.0492C24.393 48.4613 24.0588 48.7955 23.6467 48.7955Z" fill="#FF9100"></path>
                            <path d="M26.4816 48.7955C26.0695 48.7955 25.7354 48.4614 25.7354 48.0492V47.1389C25.7354 46.7268 26.0694 46.3926 26.4816 46.3926C26.8938 46.3926 27.2279 46.7267 27.2279 47.1389V48.0492C27.2279 48.4613 26.8938 48.7955 26.4816 48.7955Z" fill="#FF9100"></path>
                            <path d="M29.3156 48.7955C28.9035 48.7955 28.5693 48.4614 28.5693 48.0492V47.1389C28.5693 46.7268 28.9034 46.3926 29.3156 46.3926C29.7278 46.3926 30.0619 46.7267 30.0619 47.1389V48.0492C30.0619 48.4613 29.7278 48.7955 29.3156 48.7955Z" fill="#FF9100"></path>
                            <path d="M32.1496 46.3926C31.7375 46.3926 31.4033 46.7267 31.4033 47.1389V48.0492C31.4033 48.3189 31.547 48.5546 31.7614 48.6856C32.1323 48.5571 32.499 48.4198 32.8613 48.2738C32.8837 48.2029 32.8959 48.1275 32.8959 48.0492V47.1389C32.8958 46.7267 32.5617 46.3926 32.1496 46.3926Z" fill="#FF9100"></path>
                            <path d="M35.252 36.5771H20.5454C19.8035 36.5771 19.2021 35.9758 19.2021 35.2339C19.2021 34.492 19.8035 33.8906 20.5454 33.8906H35.2519C35.9937 33.8906 36.5951 34.492 36.5951 35.2339C36.5952 35.9758 35.9938 36.5771 35.252 36.5771Z" fill="#FFCF67"></path>
                            <path d="M26.6437 35.2339C26.6437 34.492 27.2451 33.8906 27.987 33.8906H20.5454C19.8035 33.8906 19.2021 34.492 19.2021 35.2339C19.2021 35.9758 19.8035 36.5771 20.5454 36.5771H27.987C27.2451 36.5771 26.6437 35.9758 26.6437 35.2339Z" fill="#FEBC2B"></path>
                            <path d="M20.3871 5.12734H12.2014C11.7893 5.12734 11.4551 4.79326 11.4551 4.38105C11.4551 3.96885 11.7892 3.63477 12.2014 3.63477H20.3871C20.7992 3.63477 21.1334 3.96885 21.1334 4.38105C21.1334 4.79326 20.7992 5.12734 20.3871 5.12734Z" fill="#E9EFFF"></path>
                            <path d="M24.1066 12.5883H8.48066C8.06856 12.5883 7.73438 12.2542 7.73438 11.842C7.73438 11.4299 8.06846 11.0957 8.48066 11.0957H24.1066C24.5188 11.0957 24.8529 11.4298 24.8529 11.842C24.8529 12.2541 24.5188 12.5883 24.1066 12.5883Z" fill="#E9EFFF"></path>
                            <path d="M26.3634 23.6908H6.22285C5.81074 23.6908 5.47656 23.3567 5.47656 22.9445C5.47656 22.5324 5.81064 22.1982 6.22285 22.1982H26.3634C26.7755 22.1982 27.1097 22.5323 27.1097 22.9445C27.1097 23.3566 26.7756 23.6908 26.3634 23.6908Z" fill="#E9EFFF"></path>
                            <path d="M14.8771 9.82432C14.465 9.82432 14.1309 9.49023 14.1309 9.07803V6.98066C14.1309 6.56855 14.4649 6.23438 14.8771 6.23438C15.2894 6.23438 15.6234 6.56846 15.6234 6.98066V9.07803C15.6234 9.49014 15.2893 9.82432 14.8771 9.82432Z" fill="#8EA6FF"></path>
                            <path d="M17.7111 9.82432C17.299 9.82432 16.9648 9.49023 16.9648 9.07803V6.98066C16.9648 6.56855 17.2989 6.23438 17.7111 6.23438C18.1233 6.23438 18.4574 6.56846 18.4574 6.98066V9.07803C18.4574 9.49014 18.1232 9.82432 17.7111 9.82432Z" fill="#8EA6FF"></path>
                        </svg>

                        <p>Finance</p>
                    </li>
                    <li>
                        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M44.8376 37.7055C46.757 34.2621 47.852 30.2964 47.852 26.0744C47.852 12.8605 37.14 2.14844 23.926 2.14844C10.712 2.14844 0 12.8605 0 26.0744C0 30.2964 1.09502 34.2621 3.01436 37.7055H44.8376Z" fill="#1E45A3"></path>
                            <path d="M31.1623 30.7626C31.1627 30.7623 31.163 30.7619 31.163 30.7619C31.1054 30.7457 31.0467 30.7309 30.9884 30.7154C30.9691 30.7104 30.9499 30.7048 30.9306 30.6997L30.9302 30.7C30.7336 30.6483 30.536 30.5984 30.336 30.5522C29.3446 29.5608 29.0933 27.6813 29.0481 26.3662C28.5307 26.9688 27.9877 27.473 27.4689 27.8758C26.4579 28.6608 25.2106 29.0872 23.9258 29.0872C22.641 29.0872 21.3936 28.6608 20.3827 27.8758C19.8639 27.473 19.3208 26.9688 18.8034 26.3662C18.7581 27.6813 18.5068 29.5608 17.5153 30.5522C17.3126 30.599 17.1122 30.6498 16.9129 30.7022C16.9129 30.7022 16.9127 30.702 16.9125 30.7019C16.894 30.7066 16.8758 30.7119 16.8573 30.7168C16.7983 30.7324 16.739 30.7475 16.6802 30.7636C16.6802 30.7636 16.681 30.7643 16.6813 30.7646C15.7677 31.0147 14.886 31.3207 14.0244 31.6819V34.0125C15.6306 36.2079 17.7796 37.5767 19.0097 38.0937C19.5625 38.326 20.1518 38.4438 20.7612 38.4438H22.4882L22.9106 38.5902H24.9408L25.3632 38.4438H27.0901C27.6995 38.4438 28.2888 38.3259 28.8416 38.0937C30.0648 37.5795 32.1966 36.2234 33.7998 34.0496V31.6708C32.9444 31.3138 32.0692 31.0107 31.1623 30.7626Z" fill="#FFAA7B"></path>
                            <path d="M20.3827 27.8759C19.8639 27.4729 19.3208 26.9688 18.8034 26.3662C18.7581 27.6813 18.5068 29.5608 17.5154 30.5522C17.3126 30.599 17.1123 30.6498 16.913 30.7022C16.913 30.7022 16.9128 30.7021 16.9126 30.702C16.8941 30.7068 16.8759 30.712 16.8574 30.717C16.7984 30.7326 16.7391 30.7477 16.6803 30.7638C16.6803 30.7638 16.6811 30.7645 16.6814 30.7647C16.3249 30.8624 15.9745 30.9711 15.627 31.0857C16.5949 33.397 18.6268 37.3772 20.7764 36.2747C23.7597 34.7447 23.9258 29.0872 23.9258 29.0872C22.641 29.0872 21.3936 28.6608 20.3827 27.8759Z" fill="#FC9460"></path>
                            <path d="M32.5081 35.457C31.1784 36.6858 29.7561 37.4162 28.8415 37.8006C28.2887 38.0329 27.6994 38.1507 27.0901 38.1507H25.3631L24.9407 38.2972C24.214 38.2972 23.6372 38.2972 22.9105 38.2972L22.4881 38.1507H20.7611C20.1518 38.1507 19.5625 38.0328 19.0096 37.8006C18.813 37.718 18.5919 37.6183 18.3543 37.5022V44.7701C18.3543 44.7701 16.4917 46.6098 17.8635 49.2233C19.7997 49.7289 21.8309 49.9995 23.9256 49.9995C29.7366 49.9995 35.0633 47.9273 39.2084 44.4821C39.2084 44.3627 39.2084 44.2442 39.2084 44.1284C39.2084 40.4902 34.848 37.0443 32.5081 35.457Z" fill="#F7B422"></path>
                            <path d="M18.5498 44.7709V37.5965C17.6579 37.1788 16.4719 36.499 15.3499 35.4639C13.0148 37.0471 8.65234 40.4912 8.65234 44.1278V44.4909C11.3595 46.7386 14.5702 48.4005 18.0901 49.2829C16.659 46.6383 18.5498 44.7709 18.5498 44.7709Z" fill="#F7B422"></path>
                            <path d="M44.8374 37.7052C44.8165 37.6648 44.7961 37.6239 44.775 37.5841C43.9937 36.1144 42.6815 34.9977 41.1158 34.4322L34.4919 32.04C34.6139 32.286 34.6969 32.65 34.4608 33.0509C33.8434 34.1 33.086 34.9522 32.3076 35.6385C34.4953 37.0966 38.9269 40.4935 38.9269 44.0855V44.7121C41.319 42.7844 43.332 40.406 44.8374 37.7052Z" fill="#FFCF67"></path>
                            <path d="M13.3901 33.0509C13.1541 32.65 13.2372 32.286 13.3591 32.04L6.73516 34.4322C5.16963 34.9977 3.85742 36.1144 3.07607 37.5841C3.05488 37.6239 3.03447 37.6648 3.01367 37.7052C4.51904 40.4059 6.53203 42.7844 8.92422 44.7121C8.92422 44.498 8.92422 44.2884 8.92422 44.0855C8.92422 40.4935 13.3557 37.0967 15.5434 35.6385C14.7649 34.9521 14.0077 34.1 13.3901 33.0509Z" fill="#FFCF67"></path>
                            <path d="M35.1706 28.6361C34.4469 26.9475 32.0995 25.9604 29.0092 24.9258L28.7972 26.6181C28.7972 26.6181 30.7475 34.3142 24.9414 38.298H27.7654C28.5564 38.298 29.3388 38.1317 30.0594 37.8052C30.9652 37.3946 32.2356 36.7274 33.3678 35.8021C35.484 34.0726 36.2473 31.1482 35.1706 28.6361Z" fill="#FEE77F"></path>
                            <path d="M19.2766 33.3283C18.2011 33.0515 15.4436 32.6905 14.709 35.98C15.782 36.8132 16.9469 37.4213 17.7935 37.805C18.514 38.1316 19.2963 38.2978 20.0873 38.2978H22.9113C20.9123 36.9261 19.8329 35.1144 19.2766 33.3283Z" fill="#FEE77F"></path>
                            <path d="M19.0552 26.6181L18.8432 24.9258C15.7529 25.9604 13.4055 26.9474 12.6818 28.6361C11.6051 31.1482 12.3684 34.0726 14.4847 35.802C14.6115 35.9056 14.7401 36.0054 14.8696 36.1024C15.6031 32.1758 19.3833 33.653 19.3833 33.653L19.3834 33.6523C18.1633 30.1402 19.0552 26.6181 19.0552 26.6181Z" fill="#FFDD40"></path>
                            <path d="M18.55 44.7702C18.0917 44.7702 17.7168 44.3953 17.7168 43.937V37.093C17.7168 36.6347 18.0917 36.2598 18.55 36.2598C19.0083 36.2598 19.3832 36.6347 19.3832 37.093V43.937C19.3832 44.3953 19.0083 44.7702 18.55 44.7702Z" fill="#DEEEFF"></path>
                            <path d="M29.2746 44.7702C28.8163 44.7702 28.4414 44.3953 28.4414 43.937V37.093C28.4414 36.6347 28.8163 36.2598 29.2746 36.2598C29.7329 36.2598 30.1078 36.6347 30.1078 37.093V43.937C30.1078 44.3953 29.7329 44.7702 29.2746 44.7702Z" fill="#DEEEFF"></path>
                            <path d="M39.1207 7.59277C37.3853 8.77881 36.0719 10.5949 36.5292 13.3252C37.7275 20.4788 32.2081 24.1284 40.8342 27.9638C43.9011 29.3275 45.8328 30.9228 47.0356 32.2867C47.5672 30.3057 47.8514 28.2233 47.8514 26.0743C47.8514 18.6306 44.4518 11.981 39.1207 7.59277Z" fill="#0F358F"></path>
                            <path d="M8.73067 7.59277C10.4661 8.77881 11.7795 10.5949 11.3222 13.3252C10.1239 20.4788 15.6433 24.1284 7.01719 27.9638C3.95029 29.3275 2.01865 30.9228 0.815821 32.2867C0.28418 30.3057 0 28.2233 0 26.0743C0 18.6306 3.39961 11.981 8.73067 7.59277Z" fill="#0F358F"></path>
                            <path d="M33.2459 15.4377C33.881 12.8475 34.3998 8.96648 33.0264 5.73718C30.3709 -0.507357 23.1436 -0.122396 21.2284 0.0975261C21.0273 0.120573 20.9264 0.349968 21.0408 0.516862C22.1888 2.19284 17.6911 0.674382 15.2464 5.80232C13.697 9.05222 14.0864 12.8786 14.6528 15.4378H33.2459V15.4377Z" fill="#0F358F"></path>
                            <path d="M23.8213 6.88006C23.8213 6.88006 23.1014 5.2244 24.9246 3.85721C26.7453 2.49178 24.0311 0.0385535 24.0241 0.0322058C22.8029 -0.045138 21.7883 0.0329871 21.2284 0.0973426C21.0273 0.120487 20.9264 0.349784 21.0408 0.516679C22.1888 2.19266 17.6911 0.674199 15.2464 5.80213C13.697 9.05213 14.0864 12.8785 14.6528 15.4376H18.9029L20.2529 14.9013L23.8213 6.88006Z" fill="#022068"></path>
                            <path d="M33.619 15.1094C33.2184 14.7423 32.3226 14.9284 31.9774 15.1532C32.053 15.624 32.0573 16.1056 31.9921 16.5812L31.5849 19.5472C31.5849 19.7863 31.5773 20.0209 31.5635 20.2513C32.5565 20.5508 33.1475 19.6593 33.2306 19.0475C33.2663 18.7841 33.3248 18.5246 33.406 18.2713C33.67 17.4465 34.623 16.0295 33.619 15.1094Z" fill="#FFAA7B"></path>
                            <path d="M14.2328 15.1094C14.6335 14.7423 15.5293 14.9284 15.8745 15.1532C15.7989 15.624 15.7946 16.1056 15.8599 16.5812L16.2671 19.5472C16.2671 19.7863 16.2746 20.0209 16.2885 20.2513C15.2954 20.5508 14.7045 19.6593 14.6214 19.0475C14.5857 18.7841 14.5272 18.5246 14.446 18.2713C14.1819 17.4465 13.2289 16.0295 14.2328 15.1094Z" fill="#FC9460"></path>
                            <path d="M31.4773 13.5276C31.026 12.8197 31.1755 12.2415 31.1829 11.5644C31.1829 11.5644 31.5635 8.44752 27.7248 7.34078C26.6134 7.02037 25.0452 6.82877 23.4382 6.89039C22.32 7.67877 21.5835 8.8356 21.5835 10.4749C21.5835 11.2283 21.7459 11.8171 21.2835 12.5809C20.785 13.4046 20.4949 14.5928 20.6596 15.8565L21.0746 19.039C21.0746 23.6144 23.3937 26.7243 25.3665 28.3376C25.6471 28.5671 25.9466 28.7656 26.259 28.9363C26.688 28.7474 27.0949 28.5062 27.4691 28.2156C29.4057 26.7117 31.6824 23.8128 31.6824 19.5478L32.0896 16.5818C32.2502 15.4131 31.9717 14.3034 31.4773 13.5276Z" fill="#FFC7AB"></path>
                            <path d="M25.3865 28.1505C23.4499 26.5667 21.1731 23.5138 21.1731 19.0221L20.7658 15.8978C20.6041 14.6573 20.8888 13.4908 21.3783 12.6821C21.8321 11.9322 21.6727 11.3543 21.6727 10.6146C21.6727 8.84961 22.5407 7.65273 23.8211 6.87988C20.4094 6.92705 16.6692 8.0835 16.6692 11.5637C16.6692 12.266 16.8287 12.8148 16.3748 13.527C15.8853 14.2948 15.6006 15.4024 15.7623 16.5804L16.1696 19.5471C16.1696 23.8121 18.4463 26.711 20.383 28.2149C21.3938 28.9998 22.6413 29.4262 23.9261 29.4262C24.8099 29.4262 25.6761 29.2244 26.4597 28.8429C26.0809 28.6548 25.7206 28.4237 25.3865 28.1505Z" fill="#FFAA7B"></path>
                            <path d="M29.4089 22.9824C29.1825 21.632 28.2081 20.2799 26.9074 19.9849L24.9192 19.4008C24.5736 19.2993 24.2276 19.4766 24.0884 19.7775C24.063 19.8325 23.9862 19.8325 23.9607 19.7775C23.8214 19.4766 23.4754 19.2993 23.1298 19.4008L21.1416 19.9849C21.0923 19.9961 21.0437 20.0092 20.9953 20.0233H21.0139C20.8033 20.5189 20.4392 21.7494 21.4755 22.6896C21.8102 22.5188 22.1682 22.388 22.544 22.305L22.8991 22.2266C23.4166 22.1123 23.8169 21.607 23.9637 21.0687C23.9805 21.0068 24.0684 21.0068 24.0853 21.0687C24.2321 21.607 24.6323 22.1123 25.1499 22.2266L25.5051 22.305C26.5258 22.5303 27.4202 23.1015 28.0528 23.8998C27.9218 24.482 27.5112 25.3668 26.2385 25.8165C25.3846 26.1182 24.4528 25.5812 24.3434 24.6923C24.342 24.6808 24.3407 24.6692 24.3394 24.6575C24.3214 24.4918 24.1827 24.3644 24.0137 24.3644C23.8449 24.3644 23.706 24.4918 23.688 24.6575C23.6868 24.6692 23.6854 24.6808 23.684 24.6923C23.6252 25.1701 23.3278 25.5445 22.9335 25.7435C22.824 26.1778 22.6785 27.1144 23.132 28.0237C23.4599 28.6815 24.0356 29.1904 24.8464 29.5433C25.2572 29.4604 25.6596 29.3236 26.0413 29.1293C28.0311 28.1163 30.6765 26.3564 31.5227 21.8286C31.5922 21.4564 31.6276 21.0778 31.6276 20.699C31.3054 21.494 30.6359 22.4502 29.4089 22.9824Z" fill="#0F358F"></path>
                            <path d="M21.7966 29.1288C22.8239 29.6523 23.9982 29.7707 25.0932 29.4872C22.4176 28.4501 22.9706 26.1141 23.1162 25.635C22.7494 25.8944 22.2578 25.9828 21.789 25.8172C20.6141 25.4021 20.1087 24.6158 19.8914 24.0394C20.3373 23.4294 20.9313 22.9408 21.6176 22.6206C20.4894 21.645 21.0248 20.3229 21.1988 19.9688L21.1417 19.9855C19.8152 20.2863 18.8276 21.6864 18.627 23.063C17.2698 22.5513 16.5634 21.536 16.2246 20.6997V20.7001C16.2246 21.0783 16.2599 21.4563 16.3293 21.8281C17.1739 26.355 19.8066 28.1148 21.7966 29.1288Z" fill="#022068"></path>
                        </svg>
                        <p>Human Resources</p>
                    </li>
                </ul>
            </div>
            <div  className="col-md-6 left">
                <div  className="ent-acc">
                    <h5 style={{color:'grey'}}>Serving healthcare, legal, government and more</h5>
                    <h2 style={{color:'black'}}>Specialized interpretation services for <span  style={{fontWeight:'bold',color:'#3b5d50'}}> all fields.</span></h2>
                    <p >You need more than just an interpretation agency. We are here to provide you with specialized
                       industry specific services for all fields, be it medical interpreting, legal interpreting,
                       multilingual customer support assistance, interpretation for government departments, language
                       support for your corporate meetings and for your personal needs too! </p>
                </div>
            </div>
        </div>
    </div>
</section>
        );

}

export default FieldsFeatured;